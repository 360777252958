.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.title {
    width: 100%;
    text-align: left;
    color: #1d1d1f;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.content {
    width: 100%;
    text-align: left;
    font-size: 11pt;
    color: #515154;
    margin: 10px 0px;
    line-height: 22px;
    padding: 0;
}

.space {
    width: 100%;
    height: 22px;
    margin: 10px 0px;
}
