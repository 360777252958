.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inner {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-name {
    width: 100%;
    text-align: center;
    padding: 16px 0 6px;
}
.name {
    color: #1d1d1f;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
}
