.wrapper {
    width: 100%;
}

.registration-result-page {
    width: 100%;
    margin-top: 140px;
    margin-bottom: 196px;
    text-align: left;
    min-height: 200px;
}

.order-completed_icon {
    width: auto;
    height: auto;
    margin: auto;
    text-align: center;
}

.img {
    width: 80px;
    height: 80px;
    vertical-align: middle;
    border-style: none;
}

.page-body {
    width: 100%;
}

.result {
    width: 100%;
    margin: 0;
    padding: 20px 0 4px 0;
    font-size: 18px;
    color: #339901;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
}

.page-completed_title {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #515154;
}

.continue_shoping {
    text-align: center;
    margin: 0 0 30px;
    margin-top: 26px;
}

.register-continue-button {
    min-width: 140px;
    border: none;
    background-color: #0066cc;
    border-radius: 8px;
    padding: 10px 30px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    cursor: pointer;
}
