.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 35px;
}

.title {
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1d1d1f;
    text-align: left;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ebebeb;
}

.container {
    width: 100%;
    height: 392px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-page {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #ebebeb;
    margin-top: 0;
    padding: 16px 16px 0px 0px;
}

.title-review {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1d1d1f;
    text-align: left;
    margin: 0 0 20px;
}

.str-title {
    text-align: left;
    font-size: 15px;
    line-height: 21px;
    color: #1d1d1f;
    font-weight: 700;
}

.input-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
}

.input-name .label {
    width: 100%;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #515154;
    display: flex;
    text-align: left;
    margin-bottom: 5px;
}

.inner-input-name {
    display: flex;
}

.input {
    width: 100% !important;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    height: 48px;
    max-width: 100%;
    padding: 8px 8px 8px 16px;
}

.check-input {
    margin: 0 -8px 0 3px;
    vertical-align: top;
    font-size: 20px;
    font-weight: normal;
    color: #e4434b;
}

.button-sub {
    background: #0066cc;
    color: #fff;
    min-height: 40px;
    min-width: 160px;
    z-index: 20;
    margin-top: 12px;
    padding: 8px 27px !important;
    order: 1px solid #0066cc;
    border-radius: 8px !important;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}

.comment {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    overflow-y: overlay;
    padding-left: 20px;
}

.placement-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #86868b;
    padding: 16px 16px 16px 0;
    margin: 0;
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 8px;
    padding-left: 20px;
    margin-top: 12px;
    border-bottom: 1px solid #efefef;
}

.avatar {
    display: flex;
    align-items: center;
}

.img-avatar {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.name-avatar {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    color: #444444;
    padding-left: 8px;
}

.date {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #888888;
    line-height: 15px !important;
    margin-left: 4px;
}

.review {
    width: 100%;
    margin-top: 4px;
}

.cmt {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #515154;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
}
