.wrapper {
    width: 49%;
    display: flex;
    align-items: center;
}

.img-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.slider {
    height: 100%;
    width: 100%;
    display: flex;
}

.picture {
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.picture-box {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.slide-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 122px;
}

.slide-list {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide-box {
    width: 102px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #c8bfbf;
    cursor: pointer;
}

.img-slide {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}
