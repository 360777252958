.wrapper {
    width: 100%;
    position: relative;
    padding: 0;
    text-align: right;
    flex: auto;
    border-top: none;
}

.sidebar-cart {
    position: sticky;
    top: 72px;
}

.collaterals {
    width: 100%;
    margin: 0;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    float: left;
    padding: 24px 0px;
}

.deal {
    width: 360px;
    max-width: 100%;
    margin: 0;
    padding: 0 6px;
    float: left;
    text-align: left;
}

.coupon-box {
    max-width: 100%;
    margin: 0;
    padding: 0 6px;
    text-align: left;
}

.title {
    display: none;
    margin: 0 0 5px;
    font-size: 18px;
    color: #444;
    text-align: left;
}

.hint {
    display: none;
    text-align: left;
    margin: 0 0 10px;
}

.coupon-code {
    display: flex;
    text-align: left;
    overflow: hidden;
}

.coupon-code .discount-coupon-code {
    border-radius: 8px 0 0 8px;
    margin: 0 -1px 0 0;
    margin-left: 4px;
    border: 1px solid #ebebeb;
    float: left;
    width: 60%;
    height: 48px;
    padding: 8px;
    padding-left: 16px;
    vertical-align: middle;
    color: #777;
}

.coupon-code .apply-discount-coupon-code-button {
    border-radius: 0 8px 8px 0;
    margin-left: 0;
    float: left;
    width: 39%;
    height: 48px;
    padding: 0;
    font-size: 15px;
    display: block;
    border: none;
    background-color: #aaa;
    color: #fff;
    cursor: pointer;
}

.totals {
    width: 100%;
    border-radius: 0 0 8px 8px;
    padding: 0 16px 16px;
    display: inline-block;
    margin: 0;
    background: #fff;
    color: #444;
}

.total-info {
    width: 100%;
    padding: 5px 0px 15px;
    margin: 0 0 20px;
    border-bottom: 1px solid #ddd;
}

.cart-total {
    width: 100%;
    border-collapse: collapse;
}

.cart-total-left {
    width: 50%;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #86868b;
    text-align: left;
    padding: 8px 0;
}

.cart-total-right {
    font-weight: bold !important;
    color: #1d1d1f;
    width: 50%;
    text-align: right;
    padding: 8px 0;
}

.value-summary {
    font-size: 15px;
    line-height: 21px;
    color: #1d1d1f;
}

.order-total .cart-total-left {
    color: #1d1d1f;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
}

.order-total .cart-total-right .value-summary {
    font-size: 20px;
    color: #0066cc;
}

.terms-of-service {
    padding: 0;
    margin: 0 0 20px;
    text-align: left;
}

.d-flex {
    display: flex !important;
    align-items: baseline;
}

.rule-web {
    font-size: 15px;
    line-height: 24px;
    color: #444;
}
.read {
    color: #0066cc;
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 0 3px;
}

.checkout-buttons {
    text-align: right;
}

.checkout-button {
    display: inline-block;
    min-width: 160px;
    padding: 10px 20px;
    color: #fff;
    border: none;
    background: #0066cc !important;
    width: 100% !important;
    border-radius: 8px;
    height: 48px;
    font-size: 14px;
    cursor: pointer;
}

.note-ck {
    text-align: left;
    color: #e4434b;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
}

.addon-buttons {
    text-align: right;
    margin: 20px 0 0;
    padding: 0 10px;
}
