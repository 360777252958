.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    width: 149px;
    height: 39px;
}

.button {
    min-width: 144px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    cursor: pointer;
}

.name-btn {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1f;
}

.active {
    border: 1px solid #0066cc;
    .name-btn {
        color: #0066cc !important;
    }
}
