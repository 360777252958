.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    width: 1200px;
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.no-data {
    width: 100%;
    margin: 25px 0 auto 0;
    text-align: center;
    color: #777;
    font-size: 14px;
}

.order-content {
    width: 100%;
    margin: 25px 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 16px;
}

.shoping-cart-inf {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 70%;
}

.row {
    width: 30%;
    margin-right: 0;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
}
