.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    position: relative;
}

.icon {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    min-height: 50px;
    top: -15px;
    right: -8px;
    z-index: 2;
}

.icon-img {
    max-width: 100px;
    max-height: 50px;
}

.item-img {
    width: 245px;
    height: 245px;
    margin: 25px 0 20px;
}

.product {
    position: absolute;
    top: -66px;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #1d1d1f;
    min-height: 54px;
    width: 100%;
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #1d1d1f;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.price {
    width: 245px;
    height: 24px;
}

.actual-price {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0066cc;
    margin-right: 5px;
}

.old-price {
    color: #86868b;
    margin: 0 5px 0 5px;
    font-size: 14px;
    line-height: 24px;
}
