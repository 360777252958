.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    max-width: 100%;
    display: flex;
    margin-top: var(--height-header);
}

.content {
    flex: 1;
}
