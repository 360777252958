.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    margin: 60px 120px 0px 120px;
}

.item-list {
    width: 100%;
    margin-top: 120px;
}

.banner-home-bottom {
    max-width: 1200px;
    margin: auto;
}

.topic-block {
    margin: 0 0 50px;
}

.topic-block-body {
    text-align: justify;
    line-height: 24px;
    font-size: 16px;
}
