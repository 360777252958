.wrapper {
    width: 29.3%;
    float: left;
    box-sizing: border-box;
    padding-right: 0;
}

.account-page-title {
    margin-left: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-bottom: 35px;
    position: relative;
}

.box {
    margin-bottom: 80px;
    position: static;
}

.info-box {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 0 20px 8px;
}

.block-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 26px 0 31px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
}

.customer-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000;
    display: block;
    margin-right: 10px;
}

.items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

.item {
    margin: 0;
    padding: 0;
    display: list-item;
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        padding: 10px 12px 10px 0;
        background-color: transparent;
        border: none;
        display: block;
    }
    strong {
        display: none;
    }
    .delimiter {
        margin: 20px 0 8px;
        border-top: 1px solid #e1e1e1;
        display: block;
        padding: 0;
    }
    &:hover {
        border-radius: 8px;
        background-image: linear-gradient(38deg, rgb(238, 238, 238) 10%, rgb(255, 255, 255) 90%);
        transition: 0.3s linear;
    }
}

.active {
    position: relative;
    &::before {
        content: '';
        width: 4px;
        top: 8px;
        bottom: 8px;
        left: -20px;
        background: #000;
        display: block;
        position: absolute;
    }
    span {
        display: none;
    }
    strong {
        font-size: 16px;
        line-height: 20px;
        color: #000;
        padding: 10px 12px 10px 0;
        background-color: transparent;
        border: none;
        display: block;
    }
}
