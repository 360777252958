.wrapper {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 550px;
    overflow-y: overlay;
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 8px;
    padding-left: 20px;
    margin-top: 12px;
    border-bottom: 1px solid #efefef;
}

.active,
.no-active {
    margin-right: 4px;
    font-size: 15px;
    cursor: pointer;
}

.active {
    white-space: nowrap;
    color: #feb700;
}

.no-active {
    white-space: nowrap;
    color: lightgrey;
}

.avatar {
    display: flex;
    align-items: center;
}

.img-avatar {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.name-avatar {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    color: #444444;
    padding-left: 8px;
}

.date {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #888888;
    line-height: 15px !important;
    margin-left: 4px;
}

.quality {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4px;
}

.review {
    width: 100%;
    margin-top: 4px;
}

.cmt {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #515154;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
}
