.wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.banner-img {
    height: 166px;
    object-fit: cover;
}
