.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.category-name {
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    text-align: center;
}

.category-link {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1d1d1f;
    padding-bottom: 24px;
    text-decoration: none;
}

.category-box {
    width: 100%;
    height: 480px;
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 12px;
}

.item-box {
    width: 285px;
    height: 408px;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 24px 20px 16px 20px;
    cursor: pointer;
    &:hover {
        border-color: #02010100;
        box-shadow: 1px 1px 28px 0px rgb(0 0 0 / 12%);
    }
}

.show-all {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
}

.show-btn {
    height: 100%;
    border: 1px solid #0066cc;
    border-radius: 8px;
    padding: 5.5px 45px;
    color: #0066cc;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    display: inline-block;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    &:hover {
        color: #fff;
        background: #0066cc;
    }
}

.show-icon {
    margin-left: 5px;
}
