.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: var(--height-header);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-header);
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    display: flex;
    margin-left: 121px;
    width: 173px;
    height: 45px;
    justify-content: center;
}

.logo-item {
    // width: 86px;
    // height: 45px;
    // margin-left: 86px;
    max-width: 100%;
    max-height: 100%;
}

.button-list {
    display: flex;
}

.button-user {
    display: flex;
    align-items: center;
    margin-right: 200px;
}

.search-icon {
    background: url(https://shopdunk.com/images/uploaded-source/icon/search-icon-1.png) left center no-repeat;
    background-position: 60%;
    padding: 30px 15px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
    width: 40px;
}

.cart {
    position: relative;
    cursor: pointer;
}

.cart-icon,
.user-icon {
    padding: 30px 15px;
    margin-right: 10px;
    font-size: 15px;
    color: var(--white);
    cursor: pointer;
}

.badge {
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 0px 6px;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--white);
    font-family: var(--font-family);
    background-color: var(--primary);
}

//Tippy menu service

.menu-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250%;
}

.menu-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-header);
    border-radius: 0px 0px 5px 5px;
}

.menu-item {
    display: block;
    padding: 12px 16px;
    line-height: 20px;
    font-size: 15px;
    color: #d2d2d7;
    cursor: pointer;
    border-top: 1px solid #727272;
    &:hover {
        color: #fff !important;
        background-color: #626265;
        text-decoration: none;
        border-radius: 5px;
    }
}

.menu-user {
    min-width: 248px;
    position: absolute;
    top: 55px;
    right: 0px;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 0;
    text-align: left;
    box-shadow: -2px 3px 20px 0px #000000a1;
}

.menu-item-user {
    display: block;
    text-align: left;
    border-bottom: 1px solid #d2d2d7;
    margin: 0 32px;
    line-height: 45px;
    text-transform: none;
}

.register,
.login,
.user-name,
.wishlist,
.cart,
.logout {
    font-size: 14px;
    color: #1d1d1f;
    &:hover {
        color: #0066cc !important;
    }
}

.user-name {
    font-weight: 800;
}

.item-bottom {
    border-bottom: 0px solid #d2d2d7;
}
