.wrapper {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.item {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 0;
    max-width: 100% !important;
    box-sizing: border-box;
    cursor: pointer;
}

.img-item {
    max-width: 100%;
    max-height: 100%;
    margin: 10px 0px;
}

.name-item {
    margin: 10px 0px;
    font-size: 24px;
    color: #1d1d1f;
    line-height: 22px;
    width: 100%;
    text-align: center;
}

.title {
    color: #06c;
    font-size: 16px;
    margin: 10px 0px;
    text-align: center;
}
