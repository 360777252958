.wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.series-menu {
    width: 80%;
    height: 100%;
    display: flex;
    margin-top: 7px;
    margin-bottom: 20px;
    overflow-y: overlay;
    &::-webkit-scrollbar {
        background-color: #f5f5f5;
        border-radius: 8px;
        width: 5px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #0066cc;
        border-radius: 10px;
    }
}

.series-btn {
    width: auto;
    margin-right: 40px;
    flex-shrink: 0;
}

.series-name {
    display: flex;
    &.active {
        .active-s {
            display: flex;
            color: #0066cc;
            font-weight: 700;
            font-size: 15px;
            line-height: 21px;
            border-bottom: 2px solid #0066cc;
            padding-bottom: 9px !important;
        }
        .no-active-s {
            display: none;
        }
    }
}

.active-s,
.no-active-s {
    display: flex;
}

.active-s {
    display: none;
}

.no-active-s {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #515154;
    white-space: nowrap;
}
