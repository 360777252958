.wrapper {
    margin-top: 90px;
    padding-bottom: 100px;
    padding-left: 40px;
    width: 70.7%;
    float: right;
    box-sizing: border-box;
}

.page-title-wrapper {
    border-bottom: 1px solid #cacaca;
    padding-bottom: 25px;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 18px;
}

.page-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
}

.des {
    margin-top: 10px;
}
