.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 35px;
}

.title {
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1d1d1f;
    text-align: left;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ebebeb;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
