.wrapper {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    align-items: center;
}

.page-btn {
    border-radius: 8px;
    background-color: #fff;
    border-color: transparent;
    color: #1d1d1f;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 4%);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    border: 1px solid #ddd;
    margin: 0px 5px;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.activeBtn {
    background-color: #0066cc;
    // border-radius: 8px;
    color: #fff;
}

.noActiveBtn {
    background-color: #fff;
    border-color: transparent;
    color: #1d1d1f;
    &:hover {
        border-color: transparent;
        background-color: #ddd;
    }
}
