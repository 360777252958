.wrapper {
    max-width: 1260px;
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.collumns {
    width: 1260px;
    display: block;
    box-sizing: border-box;
    &::after {
        clear: both;
        content: ' ';
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}
