.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.category-box {
    width: 100%;
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 15px;
}

.item-box {
    width: 285px;
    height: 408px;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 24px 20px 16px 20px;
    cursor: pointer;
    &:hover {
        border-color: #02010100;
        box-shadow: 1px 1px 28px 0px rgb(0 0 0 / 12%);
    }
}

.button-page {
    display: flex;
    margin-top: 20px;
}
