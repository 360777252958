.wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    margin: 5px auto;
    margin-top: 0px;
}

.over-rivew {
    width: 100%;
    height: 720px;
    overflow: overlay;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }
}

.inner {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.info {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.product-name {
    width: 100%;
    margin-bottom: 15px;
}

.name {
    font-size: 24px;
    color: #1d1d1f;
    font-weight: 700;
    line-height: 36px;
    padding: 0;
    margin: 0;
}

.rating-star {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 14px;
    display: flex;
    align-items: center;
}

.review-box {
    display: inline-block;
    align-items: center;
    padding-right: 10px;
}

.rating {
    width: 80px;
    height: 15px;
    background: url('https://shopdunk.com/images/uploaded-source/icon/star-unset.png') repeat space;
}

.review-links {
    display: flex;
    align-items: center;
    color: #0066cc;
}

.links {
    color: #0066cc;
    padding-right: 5px;
}

.overview-btn {
    margin: 0;
    align-items: center;
    display: flex;
}

.add-to-wishlist {
    width: 100%;
    margin: 0 auto 0px;
}

.button-2 {
    display: block;
    width: 100%;
    border: none;
    background-color: #fff;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 0px 24px;
    text-align: left;
    font-size: 14px;
    color: #0066cc;
    margin-left: 10px;
    cursor: pointer;
    background-image: url('https://shopdunk.com/images/uploaded-source/icon/compare-icon.png');
}

.storelocation-all {
    position: relative;
}

.storelocation-ddl {
    width: max-content;
    height: 32px;
    padding: 6px;
    font-size: 14px !important;
    color: #515154;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-top: 12px;
    min-height: 38px;
    background-image: url('https://shopdunk.com/images/uploaded-source/icon/arrow-down.png');
    background-size: 11px;
    background-repeat: no-repeat;
    background-position-x: 91%;
    background-position-y: 15px;
    -webkit-appearance: none;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 32px;
    min-width: 160px;
    position: absolute;
    top: -40px;
    left: 80px;
}

.prices {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 0px;
}

.product-prices {
    font-size: 20px;
    font-weight: bold;
    color: #444;
}

.low-prices {
    display: inline-block;
    color: #0066cc;
    font-size: 24px;
    font-weight: bold;
}

.old-product-prices {
    font-size: 14px;
    color: #999;
    text-decoration: line-through;
}

.old-prices {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding-left: 20px;
}

.attributes {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 14px;
}

.attributes-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.name-attributes {
    display: flex;
    white-space: nowrap;
    font-weight: bold;
    color: #444;
}

.label {
    font-weight: 400;
    font-size: 13px;
    color: #515154;
    line-height: 20px;
    margin-bottom: 11px;
}

.value-attributes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.value-item,
.value-item-color {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 8px 15.668px;
    cursor: pointer;
    min-width: 70px;
    min-height: 38px;
    margin-right: 12px;
}

.check-attr {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #86868b;
    margin-left: 0;
}

.value-item-active {
    border: 2px solid #0066cc !important;
}

.value-item-color {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 28px;
    min-height: 28px;
    padding: 0;
    border: none;
}

.attribute-square-container {
    display: block;
    position: relative;
    z-index: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid transparent;
    padding: 2px;
    background-color: #ffffff;
}

.attribute-square {
    border-radius: 100%;
    box-shadow: inset 1px 1px 2px rgb(0 0 0 / 10%);
    border: unset !important;
    display: inline-block !important;
    width: 28px !important;
    height: 28px !important;
    cursor: pointer;
    position: absolute;
    top: 2.8px;
    left: 2.8px;
}

.input-color {
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0;
    display: none;
}

.attribute-square-container-active {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #0066cc;
    padding: 3px;
}

.short-des {
    margin: 0;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.p-title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px 16px 12px 15px;
}

.icon {
    padding-right: 10px;
    font-size: 18px;
}

.title {
    color: #1d1d1f;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
}

.inf-promotions {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-bottom: 0;
}

.option-list {
    padding-left: 33px;
    list-style: none;
}

.option-item {
    margin-bottom: 17px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #515154;
    display: flex;
}

.input-option {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding-left: 16px;
}

.label-option {
    border: unset;
    padding: 0 10px 0;
    margin: 0;
}

.input-option + .label-option {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
}

.ud_mo {
    padding: 12px 16px 12px 15px;
}

.popup-store {
    width: 100%;
    font-size: 13px;
    color: #0066cc;
    line-height: 14px;
    padding-bottom: 20px;
    display: block;
    cursor: pointer;
}

.popup-icon {
    font-size: 16px;
    padding-right: 10px;
}

.add-cart-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-to-cart {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;
}

.add-click {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 17px !important;
    line-height: 25px !important;
    background: #0066cc;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    border: none;
    padding: 0px 24px;
    cursor: pointer;
}

.button-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.choose-btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    border: 2px solid #0066cc;
    border-radius: 8px;
    min-height: 56px;
    width: 48.5%;
    align-items: center;
    display: flex;
    color: #0066cc;
    justify-content: center;
}

.product-policy {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 15px 16px;
    line-height: 20px;
    margin-top: 17px;
}

.list-item {
    list-style: none;
}

.item {
    font-size: 13px;
    line-height: 24px;
    color: #515154;
    display: flex;
    align-items: center;
}

.item-icon {
    font-size: 16px;
    padding-right: 10px;
    color: #0066cc;
}

.item-link {
    color: #007edb;
    user-select: none;
    padding: 0px 5px;
}
