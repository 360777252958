.wrapper {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 35px;
}

.inner {
    display: flex;
    align-self: center;
}
