.wrapper {
    width: 100%;
    background-color: #fff;
    padding: 8px 10px;
}

.content-path {
    width: 1200px;
    margin: 0px 110px;
    padding: 0;
    display: flex;
}

.item {
    cursor: pointer;
}

.itemName {
    color: #515154;
    font-size: 13px;
    line-height: 32px;
    &:hover {
        color: #0066cc;
    }
}
.deLimiter {
    color: #515154;
    font-size: 10px;
    line-height: 1;
    padding: 0 10px;
}
